import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Eksternal styling
import styles from './styles';

// Material UI component
import { withStyles, Grid, Typography, Button } from '@material-ui/core';

// Shared Component
import Paper from '../Paper';

//Helper
import { isKasbon, rupiahFormat } from 'helpers/Utility';
import CustomDrawer from 'components/CustomDrawer';
import TransactionDetail from 'components/TransactionDetail';
import { SCHEME_CODE_KASBON } from 'common/constants';
const TITLE_DASHBORAD_PINANG = 'Cicilan Berikutnya';
const TITLE_DASHBORAD_KASBON = 'Sisa Tagihan Flexi Payday';
const TITLE_WRITE_OF = 'Tunggakan Pinjaman';

const DisbursementPanel = ({
  classes,
  amount,
  overdueDays,
  isLate,
  isPaymentAllowed,
  repaymentHandler,
  schemeCode,
  totalOutstandingAmount,
  chargeOfFlag,
  totalOutstandingPriAmount,
  overdueInstallmentInterest,
  overdueInstallmentPenality,
  dueDatePanel,
  arrowHandler,
  currentInstallement,
  totalInstallement,
  gracePeriod
}) => {
  const rootClassName = classNames(
    classes.isCicil,
    isKasbon(schemeCode) ? classes.rootKasbon : classes.rootCicil
  );
  const [drawer, setDrawer] = React.useState(false);

  const getTitleCardLoanActive = () => {
    if (isKasbon(schemeCode)) {
      return TITLE_DASHBORAD_KASBON;
    } else {
      if (chargeOfFlag === 'Y') return TITLE_WRITE_OF;
      else return TITLE_DASHBORAD_PINANG;
    }
  };

  const getTotalAmount = () => {
    if (isKasbon(schemeCode) || chargeOfFlag === 'Y')
      return rupiahFormat.format(totalOutstandingAmount);
    else return rupiahFormat.format(Number(amount) + Number(gracePeriod));
  };

  const writeOffConfirmPayment = () => {
    window.location.href = 'https://wa.me/6281210000494';
    // 0812-1000-0494
  };

  return (
    <>
      <Paper className={rootClassName} elevation={0} squared={false}>
        <Grid container direction="column" spacing={1}>
          <Grid container direction="row" item justify="space-between">
            <Grid item>
              <Typography className={classes.whiteText} variant="h6">
                {getTitleCardLoanActive()}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignItems="center" container direction="row" item spacing={1}>
            <Grid item>
              <Typography className={classes.whiteText} variant="h3">
                Rp. {getTotalAmount()}
              </Typography>
            </Grid>
          </Grid>
          {chargeOfFlag !== 'Y' && (
            <Grid
              alignItems="center"
              container
              direction="row"
              justify="space-between"
              style={{ marginTop: '4px', color: 'white' }}
            >
              <Grid className={classes.autodebetGrid} item>
                <Typography className={classes.autodebetTypo} variant="caption">
                  Autodebet {dueDatePanel}
                </Typography>
              </Grid>
              <Grid className={classes.rincianGrid} item>
                <Button onClick={arrowHandler}>
                  <Typography className={classes.rincianTypo} variant="caption">
                    Rincian
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
      {isLate && (
        <div
          className={
            chargeOfFlag === 'Y' ? classes.lateBoxWriteOff : classes.lateBox
          }
        >
          <Typography
            align="center"
            className={classes.redText}
            variant="caption"
          >
            Terlambat {overdueDays} hari
          </Typography>
        </div>
      )}

      {schemeCode !== SCHEME_CODE_KASBON && chargeOfFlag !== 'Y' && (
        <Paper className={classes.root2} elevation={0} squared={false}>
          <div className={classes.container}>
            <div className={classes.progressSegment}>
              {/* cuurentInstallment - pendingInstallment */}
              {[...Array(currentInstallement)].map((x, i) => (
                <div className={classes.itemColor} key={i} />
              ))}
              {/* totalInstallement - currentInstallement */}
              {[...Array(totalInstallement - currentInstallement)].map(
                (x, i) => (
                  <div className={classes.item} key={i} />
                )
              )}
            </div>
          </div>
          <Grid container direction="column">
            <Grid className={classes.installmentInformation} item>
              <Typography variant="caption">
                {currentInstallement} dari {totalInstallement} cicilan
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Grid item>
              <Button
                className={classes.payButton}
                disabled={isPaymentAllowed === 'N'}
                fullWidth
                onClick={repaymentHandler}
                variant="outlined"
              >
                Bayar Lunas Pinjaman
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {chargeOfFlag === 'Y' && (
        <>
          <Paper
            className={classes.redBoxWriteOff}
            elevation={0}
            squared={false}
          >
            <Grid container direction="column" spacing={1}>
              <Grid container direction="column" item>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <img
                        alt="warning"
                        src="images/assets/warning_write_off.svg"
                      />
                    </Grid>
                    <Grid item>
                      <Typography align="left" variant="h5">
                        Segera lakukan pelunasan pinjaman
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography align="left" variant="h6">
                    Mohon segera lakukan pembayaran pinjaman ke Rekening di
                    bawah ini:
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className={classes.boxWhite}
                container
                direction="row"
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h6">Bank BRI</Typography>
                </Grid>
                <Grid item>
                  <Typography align="left" variant="h5">
                    0206-0101-2174-304
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                className={classes.boxWhite}
                container
                direction="column"
                spacing={1}
              >
                <Grid container direction="row" item justify="space-between">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h6">
                      Pokok Pinjaman
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography align="left" variant="h6">
                      Rp
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="right" variant="h6">
                      {rupiahFormat.format(totalOutstandingPriAmount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" item justify="space-between">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h6">
                      Bunga
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography align="left" variant="h6">
                      Rp
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="right" variant="h6">
                      {rupiahFormat.format(overdueInstallmentInterest)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" item justify="space-between">
                  <Grid item xs={8}>
                    <Typography align="left" variant="h6">
                      Denda
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography align="left" variant="h6">
                      Rp
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="right" variant="h6">
                      {rupiahFormat.format(overdueInstallmentPenality)}
                    </Typography>
                  </Grid>
                </Grid>
                <hr />
                <Grid
                  className={classes.boxTotal}
                  container
                  direction="row"
                  item
                  justify="space-between"
                >
                  <Grid item xs={8}>
                    <Typography align="left" variant="h6">
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography align="left" variant="h5">
                      Rp
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="right" variant="h5">
                      {rupiahFormat.format(totalOutstandingAmount)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid
            className={classes.rootPaymentConfirm}
            container
            direction="column"
          >
            <Grid item>
              <Button
                className={classes.confirmButtonWriteOff}
                fullWidth
                onClick={writeOffConfirmPayment}
                variant="contained"
              >
                Konfirmasi Pembayaran
              </Button>
            </Grid>
            <Grid
              container
              direction="column"
              item
              spacing={2}
              style={{ marginTop: '10px' }}
            >
              <Grid item>
                <Typography variant="caption">
                  * Klik tombol diatas untuk melakukan Konfirmasi Pembayaran
                  melalui whatsApp dengan melampirkan bukti transfer
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  ** Untuk keperluan verifikasi pembayaran maka tim Sapa Raya
                  akan meminta data-data yang diperlukan
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <CustomDrawer
        closeHandler={() => setDrawer('')}
        drawer={drawer}
        setDrawer={setDrawer}
      >
        <TransactionDetail onSetDrawer={setDrawer} />
      </CustomDrawer>
    </>
  );
};

DisbursementPanel.propTypes = {
  amount: PropTypes.number,
  arrowHandler: PropTypes.func,
  classes: PropTypes.object.isRequired,
  currentInstallement: PropTypes.number,
  dueDatePanel: PropTypes.string,
  endDate: PropTypes.string,
  isLate: PropTypes.bool,
  loanNumber: PropTypes.string,
  overdueDays: PropTypes.string,
  pendingInstallement: PropTypes.number,
  repaymentHandler: PropTypes.func,
  totalInstallement: PropTypes.number
};

DisbursementPanel.defaultProps = {
  isLate: false
};

export default withStyles(styles)(DisbursementPanel);
