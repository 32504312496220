import React, { Suspense } from 'react';
import { Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './Routes';
import { connect } from 'react-redux';
import * as actions from './store/actions';
import theme from './theme';
import {
  CircularProgress,
  makeStyles,
  Backdrop,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { setCookie } from 'helpers/saveToCookies';
import {
  USER_LOGIN_DATA,
  PARTNER_ID,
  DEVICE_INFORMATION,
  CODE_RAYA_APPS,
  ERROR_EXCEPTION_HANDLING
} from 'common/constants';
import CryptoJS from 'crypto-js';
import {
  DrawerInfo,
  DrawerMaintenance,
  WarningDrawerContent
} from 'components';
import {
  getDecryptJwtToken,
  getDecryptRayaAppsData,
  getDeviceInformation
} from 'helpers/getQueryStringUrl';
import SafeAreaView from 'components/SafeAreaView';

const browserHistory = createBrowserHistory();

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: 'transparent',
    maxWidth: '500px',
    margin: '0 auto'
  },
  backdrop: {
    zIndex: 1305,
    color: '#fff'
  },
  snackbarAlert: {
    width: '470px'
  }
}));

const App = props => {
  const {
    appLoad,
    setParameterAccess,
    setDeviceInformation,
    descriptionBottomSheet,
    tittleBottomSheet,
    isMaintenance,
    setIsMaintenance,
    setDataRayaApps,
    setPhoneNumber,
    setPhoneNumberRaya,
    setPhoneNumberFlexiNew,
    setEktp,
    setEktpRaya,
    setCustomerName,
    setStatusAuthFlowRaya,
    setCodeAuthFlowRaya,
    setIsRayaAgreement,
    setError,
    error
    // subCode
  } = props;

  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  const classes = useStyles();

  React.useLayoutEffect(() => {
    const decodedToken = getDecryptJwtToken();
    const deviceInformation = getDeviceInformation();
    const decodedDataRayaApps = getDecryptRayaAppsData();

    /**
     * set decode token from url { partnerId, clientSecret, clientId, VTtoken, subCode } for set to cookie and redux
     */
    if (decodedToken) {
      const encryptDecodedToken = CryptoJS.AES.encrypt(
        JSON.stringify(decodedToken),
        process.env.REACT_APP_SECRET_KEY
      ).toString();

      setCookie(USER_LOGIN_DATA, encryptDecodedToken, 10);
      setCookie(PARTNER_ID, decodedToken.partnerId, 10);
      setParameterAccess(decodedToken);
    }

    /**
     * set decode device information from url { deviceId, deviceType, deviceName } for set to cookie and redux
     */
    if (deviceInformation) {
      setCookie(DEVICE_INFORMATION, JSON.stringify(deviceInformation), 10);
      setDeviceInformation(deviceInformation);
    }

    /**
     * set decode data raya apps auth flow sso from url { noHpFlexi, noHpRaya, noHpFlexiNew, name, nikFlexi, nikRaya, isRayaAgreement, status } for set to cookie and redux
     */
    if (decodedDataRayaApps) {
      setDataRayaApps(decodedDataRayaApps);

      if (decodedDataRayaApps?.noHpFlexi)
        setPhoneNumber(decodedDataRayaApps?.noHpFlexi);
      if (decodedDataRayaApps?.noHpRaya)
        setPhoneNumberRaya(decodedDataRayaApps?.noHpRaya);
      if (decodedDataRayaApps?.noHpFlexiNew)
        setPhoneNumberFlexiNew(decodedDataRayaApps?.noHpFlexiNew);
      if (decodedDataRayaApps?.nikFlexi) setEktp(decodedDataRayaApps?.nikFlexi);
      if (decodedDataRayaApps?.nikRaya)
        setEktpRaya(decodedDataRayaApps?.nikRaya);
      if (decodedDataRayaApps?.name) setCustomerName(decodedDataRayaApps?.name);
      if (decodedDataRayaApps?.isRayaAgreement)
        setIsRayaAgreement(decodedDataRayaApps?.isRayaAgreement);
      if (decodedDataRayaApps?.status)
        setStatusAuthFlowRaya(decodedDataRayaApps?.status);
      if (decodedDataRayaApps?.code) {
        setCookie(CODE_RAYA_APPS, decodedDataRayaApps?.code, 10);
        setCodeAuthFlowRaya(decodedDataRayaApps?.code);
      }
    }

    /**
     * configure disabled enabled inspect element
     */
    if (process.env.REACT_APP_INSPECT_ELEMENT === 'true') {
      // Disable right-click
      document.addEventListener('contextmenu', e => e.preventDefault());

      // Disable shortcut for inspect
      document.onkeydown = e => {
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, 'I') ||
          ctrlShiftKey(e, 'J') ||
          ctrlShiftKey(e, 'C') ||
          (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
        )
          return false;
      };
    }
  }, [setParameterAccess, setDeviceInformation, setDataRayaApps]);

  React.useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener('popstate', popstateHandler);
    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, []);

  const popstateHandler = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/login'
    ) {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('exitWebview', []);
      } else {
        browserHistory.goForward();
      }
    }
  };

  const ctrlShiftKey = (e, keyCode) => {
    return (
      (e.ctrlKey || e.metaKey) &&
      e.shiftKey &&
      e.keyCode === keyCode.charCodeAt(0)
    );
  };

  const loading = (
    <Backdrop className={classes.backdrop} open={appLoad}>
      <center>
        <CircularProgress />
      </center>
    </Backdrop>
  );

  const drawerMaintenance = (
    <DrawerMaintenance
      closeBottomSheet={() => {
        setIsMaintenance(false);
      }}
      descriptionBottomSheet={descriptionBottomSheet}
      openBottomSheet={isMaintenance}
      tittleBottomSheet={tittleBottomSheet}
    />
  );

  const drawerErrorCOCD = (
    <DrawerInfo
      actionButtonLabel="Hubungi Sapa Raya"
      onActionHandler={() => {
        setError('contact-cs');
      }}
      opened={error === 'error_cocd'}
      setOpened={() => {
        setError('contact-cs');
      }}
    >
      <WarningDrawerContent
        align="left"
        content="Data belum berhasil diverifikasi"
        srcImage="/images/assets/ktp_error.svg"
        subContent="Ada data yang tidak sesuai dan belum berhasil diverifikasi. Hubungi Sapa Raya untuk informasi lebih lanjut."
      />
    </DrawerInfo>
  );

  return (
    <SafeAreaView>
      <div className={classes.content}>
        <ThemeProvider theme={theme}>
          {loading}
          <Router history={browserHistory}>
            <Suspense
              fallback={
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-25px 0 0 -25px'
                  }}
                >
                  <CircularProgress />
                </span>
              }
            >
              <Routes />
              {ERROR_EXCEPTION_HANDLING.includes(error) ? (
                <Redirect to="/exception-handling" />
              ) : null}
              <Snackbar open={!isOnline}>
                <MuiAlert
                  className={classes.snackbarAlert}
                  elevation={6}
                  severity="error"
                  variant="filled"
                >
                  Tidak ada koneksi Internet
                </MuiAlert>
              </Snackbar>
            </Suspense>
          </Router>
          {drawerMaintenance}
          {drawerErrorCOCD}
        </ThemeProvider>
      </div>
    </SafeAreaView>
  );
};

const mapDispatchStatesToProps = states => {
  return {
    phoneNo: states.auth.phoneNo,
    token: states.auth.oauthToken,
    appLoad: states.loanApply.loading,
    error: states.auth.error,
    applicationId: states.loanApply.applicationId,
    tittleBottomSheet: states.loanApply.bottomSheet?.tittleBottomSheet,
    descriptionBottomSheet:
      states.loanApply.bottomSheet?.descriptionBottomSheet,
    isMaintenance: states.loanApply.isMaintenance
    // subCode: states.auth.subCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    appInquiry: auth => dispatch(actions.applicationInquiry(auth)),
    setParameterAccess: params => dispatch(actions.setParameterAccess(params)),
    setDeviceInformation: deviceInformation =>
      dispatch(actions.setDeviceInformation(deviceInformation)),
    setIsMaintenance: value => dispatch(actions.setIsMaintenance(value)),
    setDataRayaApps: data => dispatch(actions.setDataRayaApps(data)),
    setPhoneNumber: noHpFlexi => dispatch(actions.setPhoneNo(noHpFlexi)),
    setPhoneNumberRaya: noHpRaya => dispatch(actions.setPhoneNoRaya(noHpRaya)),
    setPhoneNumberFlexiNew: noHpFlexiNew =>
      dispatch(actions.setPhoneNumberFlexiNew(noHpFlexiNew)),
    setEktp: eKtp => dispatch(actions.setEktp(eKtp)),
    setEktpRaya: eKtpRaya => dispatch(actions.setEktpRaya(eKtpRaya)),
    setCustomerName: value => dispatch(actions.setCustomerName(value)),
    setStatusAuthFlowRaya: params =>
      dispatch(actions.setStatusAuthFlowRaya(params)),
    setCodeAuthFlowRaya: params =>
      dispatch(actions.setCodeAuthFlowRaya(params)),
    setIsRayaAgreement: params => dispatch(actions.setIsRayaAgreement(params)),
    setError: val => dispatch(actions.setError(val))
  };
};

export default connect(mapDispatchStatesToProps, mapDispatchToProps)(App);
