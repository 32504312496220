import * as actionTypes from './actionTypes';
import axios from 'lib/axios/axios';
import axiosGolang from 'lib/axios/axios-golang';
import {
  USERS,
  PAYMENT_SERVICE,
  PAYMENT_INQUIRY,
  PAYMENT_TRANSACTION
} from 'common/config';

import { loading } from './loanApply';
import { setError } from './auth';
import {
  getChannelContextCode,
  getChannelContextMsg,
  getTommorowDate
} from 'helpers/Utility';
import { SendEventRepayment } from 'helpers/analytics';

export const loanPayment = (auth, accountId, param) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + PAYMENT_SERVICE;
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
        // 'Access-Control-Request-Headers': 'ChannelContext',
        // 'ChannelContext': JSON.stringify(context)
      }
    };

    const body = {
      accountId: accountId
    };

    const tommorow = getTommorowDate(new Date());

    axiosGolang
      .post(url, body, header)
      .then(res => {
        const status = res.status;
        if (status && status === 200) {
          dispatch(fetchMessage(res.data));
          dispatch(statusPayment('SUCCESS'));
          SendEventRepayment({
            action: 'User Got Success Repayment',
            label: 'Repayment'
          });
          localStorage.setItem('freezeDateSuccess', tommorow);
          param && param.action();
          return;
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        // Pending Payment
        if (
          getChannelContextCode(err, 'channel-context') === '211150' ||
          (getChannelContextCode(err, 'channel-context') === '211151' &&
            getChannelContextMsg(err, 'channel-context').includes(
              'Permintaan Paid Off Masih Diproses'
            ))
        ) {
          dispatch(lastPayment(tommorow));
          dispatch(statusPayment('PENDING'));
          SendEventRepayment({
            action: 'User Got Pending Repayment',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '211151') {
          dispatch(statusPayment('FAILURE'));
          SendEventRepayment({
            action: 'User Got Failure Repayment',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '211152') {
          dispatch(statusPayment('INSUFFICIENT'));
          SendEventRepayment({
            action: 'User Got Insufficient Balance On Saving',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '11022') {
          dispatch(statusPayment('ERROR_ACCOUNT'));
          SendEventRepayment({
            action: 'There is a problem with the account number',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '11023') {
          dispatch(statusPayment('ERROR_SACA'));
          SendEventRepayment({
            action: 'There is a problem with the SACA, data user',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '11024') {
          dispatch(statusPayment('ERROR_NIK'));
          SendEventRepayment({
            action: 'NIK does not match from inquiry saca and cif',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === '11025') {
          dispatch(statusPayment('ERROR_CIF'));
          SendEventRepayment({
            action: 'NIK does not match from inquiry cif pinang (clad) with nik rayanet',
            label: 'Repayment'
          });
          param && param.action();
          return;
        }
        dispatch(setError('connection'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const loanPaymentInquiry = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url =
      USERS + '/custom/applications/' + auth.applicationId + PAYMENT_INQUIRY;

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(inquiryPayment(res.data.data, true));
        param && param.action();
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }
        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        // No Loan Schudeled
        if (getChannelContextCode(err, 'channel-context') === '8504') {
          dispatch(inquiryPayment('', false));
          param && param.action();
          return;
        }

        dispatch(setError('connection'));
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const loanPaymentTrasaction = (auth, param) => {
  return dispatch => {
    dispatch(loading(true));

    const url =
      USERS +
      '/' +
      (localStorage.getItem('phonePrincipal') || auth.phoneNo) +
      '/custom/applications/' +
      auth.applicationId +
      PAYMENT_TRANSACTION;

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    const body = {
      loanAccount: String(param.loanAccount),
      totalPayAmount: String(param.totalPayAmount),
      // tranIdentifier: "PAYMENT" / "BRIVA"
      tranIdentifier: 'PAYMENT'
    };

    axios
      .post(url, body, header)
      .then(res => {
        dispatch(trasactionPayment(res.data.data));
      })
      .catch(err => {
        return err;
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const inquiryPayment = (data, status) => {
  return {
    type: actionTypes.PAYMENT_INQUIRY,
    data: data,
    status: status
  };
};

export const trasactionPayment = data => {
  return {
    type: actionTypes.PAYMENT_TRANSACTION,
    data: data
  };
};

export const fetchMessage = data => {
  return {
    type: actionTypes.FECT_MESSAGE_PAYMENT,
    data: data
  };
};

export const statusPayment = value => {
  return {
    type: actionTypes.STATUS_PAYMENT,
    val: value
  };
};

export const lastPayment = value => {
  return {
    type: actionTypes.LAST_PAYMENT,
    val: value
  };
};
